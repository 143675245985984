<template>
  <div>
    <div class="mainHeader">
      <div class="headerBox">
        <div class="headerTitleA">
          <div class="textBox">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <rect
                x="48"
                y="96"
                width="416"
                height="320"
                rx="40"
                ry="40"
                fill="none"
                stroke="#783E17"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
              />
              <path
                fill="none"
                stroke="#783E17"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M112 160l144 112 144-112"
              />
            </svg>
            <div>rogan@achacha.kr</div>
          </div>
          <div class="textBox" @click="handleCall()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
                fill="none"
                stroke="#783E17"
                stroke-miterlimit="10"
                stroke-width="32"
              />
            </svg>
            <div>+82-10-4124-6539</div>
          </div>
          <div class="textBox" @click="handleInsta()">
            <svg
              fill="#783E17"
              stroke="#783E17"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
              />
              <path
                d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
                fill="#783E17"
                stroke="#783E17"
              />
            </svg>
            <div>achacha_vj</div>
          </div>
        </div>
        <img class="Logo" src="../assets/ACHACHA_LOGO.png" />
        <div class="headerTitleB">Visual Jockey & SW Development</div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  methods: {
    handleInsta() {
      window.open("https://www.instagram.com/achacha_vj/", "_blank");
    },
    handleCall() {
      location.href = "tel:+82-10-4124-6539";
    },
  },
};
</script>

<style scoped>
.mainHeader {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  height: 10vh;
  font-family: "sings-heavy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Logo {
  width: 5vw;
  height: auto;
}

.headerBox {
  display: flex;
  width: 90vw;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.headerTitleA {
  font-size: 1vw;
  align-self: flex-end;
}

.headerTitleB {
  font-size: 0.8vw;
  align-self: flex-end;
  font-family: "sings-regular", sans-serif;
}

.line {
  margin: 0 auto;
  width: 90vw;
  height: 0.15vw;
  background-color: #783e17;
  margin: 20px auto;
}

.textBox {
  display: flex;
  align-items: center;
  font-family: "sings-regular", sans-serif;
}

.textBox div {
  font-size: 0.9vw;
  margin-left: 10px;
}

svg {
  width: 1vw;
  height: auto;
}

@media (max-width: 600px) {
  .mainHeader {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    height: 10vh;
    width: 100%;
    font-family: "sings-heavy", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .Logo {
    width: 15vw;
    height: auto;
    align-self: flex-end;
  }

  .headerBox {
    display: flex;
    width: 90vw;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .headerTitleA {
    align-self: flex-end;
  }

  .headerTitleB {
    font-size: 1.8vw;
    align-self: flex-end;
    font-family: "sings-regular", sans-serif;
  }

  .line {
    margin: 0 auto;
    width: 90vw;
    height: 0.15vw;
    background-color: #783e17;
    margin: 7px auto;
  }

  .textBox {
    display: flex;
    align-items: center;
    font-family: "sings-regular", sans-serif;
  }

  .textBox div {
    font-size: 1.8vw;
    margin-left: 3px;
  }

  svg {
    width: 1.8vw;
    height: auto;
  }
}
</style>
