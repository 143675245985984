<template>
  <div
    class="Container"
    @contextmenu.prevent="preventRightClick"
    @dragstart.prevent="preventRightClick"
  >
    <div class="dataContainer">
      <div
        v-for="(data, index) in propsData"
        :key="index"
        @click="modalOpen(index)"
        class="dataBox"
      >
        <div
          :class="{
            resetInfo: !data.mouseOver,
            changeInfo: data.mouseOver,
          }"
          @mouseover="changeInfo(index)"
          @mouseleave="resetInfo(index)"
        >
          <div class="imageContainer">
            <img class="image" :src="data.image" />
            <div v-if="data.mouseOver" class="textOverlay">
              <div class="titleText">{{ rePropsData[index].name }}</div>
              <br />
              <div>DATE : {{ rePropsData[index].date }}</div>
              <div>LOCATION : {{ rePropsData[index].location }}</div>
              <div>WORK : {{ rePropsData[index].work }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-wrap" v-show="modalCheck" @click="modalOpen">
      <div class="modal-container" @click.stop="">
        <svg
          class="madalPrevArrow"
          xmlns="http://www.w3.org/2000/svg"
          width="2vw"
          height="2vw"
          viewBox="0 0 24 24"
          @click="prevImage"
        >
          <path
            fill="white"
            d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"
          />
        </svg>
        <img
          class="modalImage"
          :src="rePropsData[modalIndex]?.detail[currentImageIndex]"
          alt="ModalImage"
        />
        <svg
          class="madalNextArrow"
          xmlns="http://www.w3.org/2000/svg"
          width="2vw"
          height="2vw"
          viewBox="0 0 24 24"
          @click="nextImage"
        >
          <path
            fill="white"
            d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["propsData", "rePropsData"],
  data() {
    return {
      modalCheck: false,
      modalIndex: null,
      currentImageIndex: 0,
    };
  },
  methods: {
    preventRightClick(event) {
      event.preventDefault();
    },
    changeInfo(index) {
      this.propsData.forEach((data, i) => {
        this.$set(this.propsData[i], "mouseOver", i === index);
      });
    },
    resetInfo() {
      this.propsData.forEach((data) => {
        this.$set(data, "mouseOver", false);
      });
    },
    modalOpen(index) {
      this.modalCheck = !this.modalCheck;
      this.modalIndex = index;
      this.currentImageIndex = 0;
    },
    nextImage() {
      const totalImages = this.rePropsData[this.modalIndex]?.detail.length;

      if (totalImages && totalImages > 1) {
        this.currentImageIndex = (this.currentImageIndex + 1) % totalImages;
      }
    },
    prevImage() {
      const totalImages = this.rePropsData[this.modalIndex]?.detail.length;

      if (totalImages && totalImages > 1) {
        this.currentImageIndex =
          (this.currentImageIndex - 1 + totalImages) % totalImages;
      }
    },
  },
};
</script>

<style scoped>
.dataContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60vw;
  /* overflow: auto;
  scrollbar-width: none; */
  -ms-overflow-style: none;
  font-family: "nanumSquareB", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
}
/* .dataContainer::-webkit-scrollbar {
  display: none;
} */

.dataBox {
  width: 30vw;
  height: auto;
}

.imageContainer {
  position: relative;
}

.image {
  display: block;
  width: 30vw;
  height: auto;
  margin: 0;
  transition: filter 0.3s ease;
}

.textOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: #fff;
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}

.titleText {
  font-size: 1.5vw;
}

.resetInfo {
  display: block;
}

.changeInfo {
  opacity: 1;
  cursor: pointer;
}

.changeInfo .image {
  filter: blur(5px) brightness(30%);
}

/* modal */

.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.modal-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modalImage {
  width: 30vw;
  height: auto;
  -moz-box-shadow: 0 0 10px #fff;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0px 0px 10px #fff;
}

.madalPrevArrow {
  cursor: pointer;
  transform: scaleX(-1);
  /* -moz-box-shadow: 0 0 10px #fff; */
  /* -webkit-box-shadow: 0 0 10px #fff; */
  /* box-shadow: 0px 0px 10px #fff; */
  /* box-sizing: content-box; */
}
.madalNextArrow {
  cursor: pointer;
  /* -moz-box-shadow: 0 0 10px #fff; */
  /* -webkit-box-shadow: 0 0 10px #fff; */
  /* box-shadow: 0px 0px 10px #fff; */
  /* box-sizing: content-box; */
}

@media (max-width: 600px) {
  .dataContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80vw;
  }
  /* .dataContainer::-webkit-scrollbar {
  display: none;
} */

  .dataBox {
    width: 80vw;
    height: auto;
  }

  .imageContainer {
    position: relative;
  }

  .image {
    display: block;
    width: 80vw;
    height: auto;
    margin: 0;
    transition: filter 0.3s ease;
  }

  .textOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s ease;
    font-size: 2vw;
  }

  .titleText {
    font-size: 3vw;
  }

  .resetInfo {
    display: block;
  }

  .changeInfo {
    opacity: 1;
    cursor: pointer;
  }

  .changeInfo .image {
    filter: blur(5px) brightness(30%);
  }

  /* modal */

  .modal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  .modal-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .modalImage {
    width: 60vw;
    height: auto;
    -moz-box-shadow: 0 0 10px #fff;
    -webkit-box-shadow: 0 0 10px #fff;
    box-shadow: 0px 0px 10px #fff;
  }

  .btn_Close {
    position: absolute;
    top: 5%;
    right: 5%;
    display: none;
  }

  .madalPrevArrow {
    cursor: pointer;
    width: 5vw;
    height: auto;
    transform: scaleX(-1);
  }
  .madalNextArrow {
    cursor: pointer;
    width: 5vw;
    height: auto;
  }
}
</style>
