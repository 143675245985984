<template>
  <div id="app">
    <MainHeader />
    <MainHome />
    <!-- <MainFooter class="footer" /> -->
  </div>
</template>

<script>
import MainHeader from "./components/MainHeader.vue";
import MainHome from "./components/MainHome.vue";
// import MainFooter from "./components/MainFooter.vue";

export default {
  name: "App",
  components: {
    MainHeader,
    MainHome,
    // MainFooter,
  },
};
</script>

<style scoped>
#app {
  font-family: "sings-medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #783e17;
}
</style>
