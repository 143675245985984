<template>
  <div class="container">
    <MainData
      class="mainData"
      :propsData="propsData"
      :rePropsData="rePropsData"
    />
  </div>
</template>

<script>
import MainData from "./MainData.vue";

export default {
  components: { MainData },
  methods: {},
  data() {
    return {
      propsData: [
        {
          text: "채널 A 리브랜딩행사",
          image: require("../assets/VJ_Title_Image/VJ_title_35.png"),
        },
        {
          text: "퇴근 후 FC모바일 나는 직장인이다",
          image: require("../assets/VJ_Title_Image/VJ_title_34.png"),
        },
        {
          text: "넷플릭스 전란 제작보고회",
          image: require("../assets/VJ_Title_Image/VJ_title_33.png"),
        },
        {
          text: "2024 세계 꽃문화관광축제 호수야 놀자",
          image: require("../assets/VJ_Title_Image/VJ_title_32.png"),
        },
        {
          text: "제 85회 아뮤소",
          image: require("../assets/VJ_Title_Image/VJ_title_31.png"),
        },
        {
          text: "광화문 기후공명",
          image: require("../assets/VJ_Title_Image/VJ_title_30.png"),
        },
        {
          text: "최현우의 매직매치 2화",
          image: require("../assets/VJ_Title_Image/VJ_title_29.png"),
        },
        {
          text: "CBS 가을 음악회",
          image: require("../assets/VJ_Title_Image/VJ_title_28.png"),
        },
        {
          text: "2024 직지문화축제",
          image: require("../assets/VJ_Title_Image/VJ_title_27.png"),
        },
        {
          text: "제 84회 아뮤소",
          image: require("../assets/VJ_Title_Image/VJ_title_26.png"),
        },
        {
          text: "벨기에문화콘서트",
          image: require("../assets/VJ_Title_Image/VJ_title_25.png"),
        },
        {
          text: "마린유겐트 개소식 / 심포지엄",
          image: require("../assets/VJ_Title_Image/VJ_title_24.png"),
        },
        {
          text: "서귀포문화제 야행",
          image: require("../assets/VJ_Title_Image/VJ_title_23.png"),
        },
        {
          text: "수진 월드투어_싱가포르",
          image: require("../assets/VJ_Title_Image/VJ_title_22.png"),
        },
        {
          text: "Alyglo 출하식",
          image: require("../assets/VJ_Title_Image/VJ_title_21.png"),
        },
        {
          text: "제 82회 아뮤소",
          image: require("../assets/VJ_Title_Image/VJ_title_20.png"),
        },
        {
          text: "2024 서영은 콘서트",
          image: require("../assets/VJ_Title_Image/VJ_title_19.png"),
        },
        {
          text: "2024 심수봉 콘서트_대전",
          image: require("../assets/VJ_Title_Image/VJ_title_18.png"),
        },
        {
          text: "제 9회 BNI Korea",
          image: require("../assets/VJ_Title_Image/VJ_title_17.png"),
        },
        {
          text: "제 81회 아뮤소",
          image: require("../assets/VJ_Title_Image/VJ_title_16.png"),
        },
        {
          text: "NewJeans CF",
          image: require("../assets/VJ_Title_Image/VJ_title_15.png"),
        },
        {
          text: "제 80회 아뮤소",
          image: require("../assets/VJ_Title_Image/VJ_title_14.png"),
        },
        {
          text: "LIGHTSUM 2024 SUM&GREET",
          image: require("../assets/VJ_Title_Image/VJ_title_13.png"),
        },
        {
          text: "2024 SBS 총선 국민의 선택",
          image: require("../assets/VJ_Title_Image/VJ_title_12.png"),
        },
        {
          text: "2023 제 79회 아뮤소",
          image: require("../assets/VJ_Title_Image/VJ_title_11.jpg"),
        },
        {
          text: "2024 제53회 hy대회",
          image: require("../assets/VJ_Title_Image/VJ_title_10.jpg"),
        },
        {
          text: "2024 캐스트윈창립",
          image: require("../assets/VJ_Title_Image/VJ_title_09.jpg"),
        },
        {
          text: "2023 RS Awords",
          image: require("../assets/VJ_Title_Image/VJ_title_08.jpg"),
        },
        {
          text: "2023 태안가곡제",
          image: require("../assets/VJ_Title_Image/VJ_title_07.jpg"),
        },
        {
          text: "2023 광양 K-POP",
          image: require("../assets/VJ_Title_Image/VJ_title_06.jpg"),
        },
        {
          text: "2023 관세청비전선포식",
          image: require("../assets/VJ_Title_Image/VJ_title_05.jpg"),
        },
        {
          text: "2023 TPO",
          image: require("../assets/VJ_Title_Image/VJ_title_04.jpg"),
        },
        {
          text: "2023 퀴즈온코리아",
          image: require("../assets/VJ_Title_Image/VJ_title_03.jpg"),
        },
        {
          text: "2023 ZIC",
          image: require("../assets/VJ_Title_Image/VJ_title_02.jpg"),
        },
        {
          text: "2023 IPEF",
          image: require("../assets/VJ_Title_Image/VJ_title_01.jpg"),
        },
      ],
      rePropsData: [
        {
          name: "채널 A 리브랜딩행사",
          date: "2024.10.14",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/35.CHA_REbrand/CHA_REbrand_01.png"),
            require("../assets/35.CHA_REbrand/CHA_REbrand_02.png"),
            require("../assets/35.CHA_REbrand/CHA_REbrand_03.png"),
          ],
        },
        {
          name: "퇴근 후 FC모바일 나는 직장인이다",
          date: "2024.10.12-13",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/34.YED_FCM/YED_FCM_01.png"),
            require("../assets/34.YED_FCM/YED_FCM_02.png"),
            require("../assets/34.YED_FCM/YED_FCM_03.png"),
            require("../assets/34.YED_FCM/YED_FCM_04.png"),
            require("../assets/34.YED_FCM/YED_FCM_05.png"),
          ],
        },
        {
          name: "넷플릭스 전,란 제작발표회",
          date: "2024.10.09",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/33.NF_JR/NF_JR_01.png"),
            require("../assets/33.NF_JR/NF_JR_02.png"),
            require("../assets/33.NF_JR/NF_JR_03.png"),
          ],
        },
        {
          name: "2024 세계 꽃문화관광축제 호수야 놀자",
          date: "2024.10.02-06",
          location: "Goyang, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/32.GY_Flower/GY_Flowe_01.png"),
            require("../assets/32.GY_Flower/GY_Flowe_02.png"),
            require("../assets/32.GY_Flower/GY_Flowe_03.png"),
            require("../assets/32.GY_Flower/GY_Flowe_04.png"),
            require("../assets/32.GY_Flower/GY_Flowe_05.png"),
            require("../assets/32.GY_Flower/GY_Flowe_06.png"),
            require("../assets/32.GY_Flower/GY_Flowe_07.png"),
          ],
        },
        {
          name: "제 85회 아뮤소",
          date: "2024.09.25",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/31.AMS_85/AMS_85_01.png"),
            require("../assets/31.AMS_85/AMS_85_02.png"),
          ],
        },
        {
          name: "광화문 기후공명",
          date: "2024.09.11",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/30.GHM_GHGM/GHM_GHGM_01.png"),
            require("../assets/30.GHM_GHGM/GHM_GHGM_02.png"),
            require("../assets/30.GHM_GHGM/GHM_GHGM_03.png"),
            require("../assets/30.GHM_GHGM/GHM_GHGM_04.png"),
          ],
        },
        {
          name: "최현우의 매직매치 2화",
          date: "2024.09.06",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/29.MAGICMATCH02/MAGICMATCH02_01.png"),
            require("../assets/29.MAGICMATCH02/MAGICMATCH02_02.png"),
            require("../assets/29.MAGICMATCH02/MAGICMATCH02_03.png"),
            require("../assets/29.MAGICMATCH02/MAGICMATCH02_04.png"),
          ],
        },
        {
          name: "CBS 가을 음악회",
          date: "2024.09.05",
          location: "Cheongju, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/28.CBS_FOOL/CBS_FOOL_01.png"),
            require("../assets/28.CBS_FOOL/CBS_FOOL_02.png"),
            require("../assets/28.CBS_FOOL/CBS_FOOL_03.png"),
          ],
        },
        {
          name: "2024 직지문화축제",
          date: "2024.09.04",
          location: "Cheongju, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/27.JICJI/JICJI_01.png"),
            require("../assets/27.JICJI/JICJI_02.png"),
            require("../assets/27.JICJI/JICJI_03.png"),
            require("../assets/27.JICJI/JICJI_04.png"),
            require("../assets/27.JICJI/JICJI_05.png"),
          ],
        },
        {
          name: "제 84회 아뮤소",
          date: "2024.08.25",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/26.AMS_84/AMS_84_01.png"),
            require("../assets/26.AMS_84/AMS_84_02.png"),
            require("../assets/26.AMS_84/AMS_84_03.png"),
          ],
        },
        {
          name: "제 3회 벨기에 문화축제 기념 콘서트",
          date: "2024.08.24",
          location: "Incheon, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/25.BGECON/BGECON_01.png"),
            require("../assets/25.BGECON/BGECON_02.png"),
            require("../assets/25.BGECON/BGECON_03.png"),
            require("../assets/25.BGECON/BGECON_04.png"),
            require("../assets/25.BGECON/BGECON_05.png"),
            require("../assets/25.BGECON/BGECON_06.png"),
            require("../assets/25.BGECON/BGECON_07.png"),
            require("../assets/25.BGECON/BGECON_08.png"),
            require("../assets/25.BGECON/BGECON_09.png"),
          ],
        },
        {
          name: "마린유겐트 개소식 / 심포지엄",
          date: "2024.08.22",
          location: "Incheon, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/24.UGENT/UGENT_01.png"),
            require("../assets/24.UGENT/UGENT_02.png"),
            require("../assets/24.UGENT/UGENT_03.png"),
            require("../assets/24.UGENT/UGENT_04.png"),
            require("../assets/24.UGENT/UGENT_05.png"),
          ],
        },
        {
          name: "서귀포문화제야행 - 스토리텔러",
          date: "2024.08.14",
          location: "Jeju, Republic of Korea",
          work: "Design",
          detail: [
            require("../assets/23.SGPMHJ/SGPMHJ_01.png"),
            require("../assets/23.SGPMHJ/SGPMHJ_02.png"),
            require("../assets/23.SGPMHJ/SGPMHJ_03.png"),
            require("../assets/23.SGPMHJ/SGPMHJ_04.png"),
            require("../assets/23.SGPMHJ/SGPMHJ_05.png"),
          ],
        },
        {
          name: "수진 월드투어_싱가포르",
          date: "2024.08.08",
          location: "Singapore",
          work: "VJ Operator",
          detail: [
            require("../assets/22.SJ_Singa/SJ_Singa_01.png"),
            require("../assets/22.SJ_Singa/SJ_Singa_02.png"),
            require("../assets/22.SJ_Singa/SJ_Singa_03.png"),
            require("../assets/22.SJ_Singa/SJ_Singa_04.png"),
            require("../assets/22.SJ_Singa/SJ_Singa_05.png"),
          ],
        },
        {
          name: "Alyglo 첫 출하 기념 행사",
          date: "2024.07.08",
          location: "Cheongju, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/21.Alyglo/Alyglo_01.png"),
            require("../assets/21.Alyglo/Alyglo_02.png"),
            require("../assets/21.Alyglo/Alyglo_03.png"),
            require("../assets/21.Alyglo/Alyglo_04.png"),
            require("../assets/21.Alyglo/Alyglo_05.png"),
          ],
        },
        {
          name: "제 82회 아뮤소",
          date: "2024.06.25",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/20.AMS_82/AMS_82_01.png"),
            require("../assets/20.AMS_82/AMS_82_02.png"),
            require("../assets/20.AMS_82/AMS_82_03.png"),
          ],
        },
        {
          name: "2024 서영은 콘서트",
          date: "2024.06.15",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/19.SYECON/SYECON_01.png"),
            require("../assets/19.SYECON/SYECON_02.png"),
            require("../assets/19.SYECON/SYECON_03.png"),
            require("../assets/19.SYECON/SYECON_04.png"),
            require("../assets/19.SYECON/SYECON_05.png"),
            require("../assets/19.SYECON/SYECON_06.png"),
          ],
        },
        {
          name: "2024 심수봉 콘서트_대전",
          date: "2024.06.09",
          location: "Daejeon, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/18.SSBCON/SSBCON_01.png"),
            require("../assets/18.SSBCON/SSBCON_02.png"),
            require("../assets/18.SSBCON/SSBCON_03.png"),
            require("../assets/18.SSBCON/SSBCON_04.png"),
            require("../assets/18.SSBCON/SSBCON_05.png"),
            require("../assets/18.SSBCON/SSBCON_06.png"),
            require("../assets/18.SSBCON/SSBCON_07.png"),
          ],
        },
        {
          name: "제 9회 BNI Korea",
          date: "2024.06.03",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/17.BNIKOREA/BNIKOREA_01.png"),
            require("../assets/17.BNIKOREA/BNIKOREA_02.png"),
            require("../assets/17.BNIKOREA/BNIKOREA_03.png"),
            require("../assets/17.BNIKOREA/BNIKOREA_04.png"),
            require("../assets/17.BNIKOREA/BNIKOREA_05.png"),
            require("../assets/17.BNIKOREA/BNIKOREA_06.png"),
            require("../assets/17.BNIKOREA/BNIKOREA_07.png"),
          ],
        },
        {
          name: "제 81회 아뮤소",
          date: "2024.05.25",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/16.AMS_81/AMS_81_01.png"),
            require("../assets/16.AMS_81/AMS_81_02.png"),
            require("../assets/16.AMS_81/AMS_81_03.png"),
            require("../assets/16.AMS_81/AMS_81_04.png"),
          ],
        },
        {
          name: "NewJeans CF",
          date: "2024.05.02",
          location: "Hwaseong, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/15.NewJeansCF/NewJeansCF_01.png"),
            require("../assets/15.NewJeansCF/NewJeansCF_02.png"),
            require("../assets/15.NewJeansCF/NewJeansCF_03.png"),
            require("../assets/15.NewJeansCF/NewJeansCF_04.png"),
            require("../assets/15.NewJeansCF/NewJeansCF_05.png"),
            require("../assets/15.NewJeansCF/NewJeansCF_06.png"),
            require("../assets/15.NewJeansCF/NewJeansCF_07.png"),
          ],
        },
        {
          name: "제 80회 아뮤소",
          date: "2024.04.25",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/14.AMS_80/AMS_80_01.png"),
            require("../assets/14.AMS_80/AMS_80_02.png"),
            require("../assets/14.AMS_80/AMS_80_03.png"),
            require("../assets/14.AMS_80/AMS_80_04.png"),
          ],
        },
        {
          name: "LIGHTSUM 2024 SUM&GREET",
          date: "2024.04.20",
          location: "Seoul, Republic of Korea",
          work: "VJ Design",
          detail: [
            require("../assets/13.LIGHTSUM/LIGHTSUM_01.png"),
            require("../assets/13.LIGHTSUM/LIGHTSUM_02.png"),
            require("../assets/13.LIGHTSUM/LIGHTSUM_03.png"),
            require("../assets/13.LIGHTSUM/LIGHTSUM_04.png"),
            require("../assets/13.LIGHTSUM/LIGHTSUM_05.png"),
          ],
        },
        {
          name: "2024 국민의 선택",
          date: "2024.04.10",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/12.KMEST/KMEST_01.png"),
            require("../assets/12.KMEST/KMEST_02.png"),
            require("../assets/12.KMEST/KMEST_03.png"),
            require("../assets/12.KMEST/KMEST_04.png"),
            require("../assets/12.KMEST/KMEST_05.png"),
            require("../assets/12.KMEST/KMEST_06.png"),
            require("../assets/12.KMEST/KMEST_07.png"),
          ],
        },
        {
          name: "제79회 아뮤소",
          date: "2024.03.25",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/11.AMS/AMS_01.png"),
            require("../assets/11.AMS/AMS_02.png"),
            require("../assets/11.AMS/AMS_03.png"),
          ],
        },
        {
          name: "제53회 hy대회",
          date: "2024.03.21",
          location: "Gwangyang, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/10.hy/hy_01.png"),
            require("../assets/10.hy/hy_02.png"),
            require("../assets/10.hy/hy_03.png"),
            require("../assets/10.hy/hy_04.png"),
            require("../assets/10.hy/hy_05.png"),
            require("../assets/10.hy/hy_06.png"),
          ],
        },
        {
          name: "캐스트원 창립 33주년 기념식",
          date: "2024.02.01",
          location: "Seoul, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/09.CastWin/CastWin_01.png"),
            require("../assets/09.CastWin/CastWin_02.png"),
            require("../assets/09.CastWin/CastWin_03.png"),
            require("../assets/09.CastWin/CastWin_04.png"),
          ],
        },
        {
          name: "Richsister Queen Awards",
          date: "2023.11.17",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [require("../assets/08.RSQueen/RSQueen_01.png")],
        },
        {
          name: "제4회 돌아온 안흥진성 태안가곡제",
          date: "2023.11.02",
          location: "Taean, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/07.TASing/TASing_01.png"),
            require("../assets/07.TASing/TASing_02.png"),
            require("../assets/07.TASing/TASing_03.png"),
          ],
        },
        {
          name: "제 3회 광양 K-POP Festival",
          date: "2023.10.20 - 2023.10.21",
          location: "Gwangyang, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/06.KYKpop/KYKpop_01.png"),
            require("../assets/06.KYKpop/KYKpop_02.png"),
            require("../assets/06.KYKpop/KYKpop_03.png"),
            require("../assets/06.KYKpop/KYKpop_04.png"),
          ],
        },
        {
          name: "관세청 비전 선포식",
          date: "2023.10.05",
          location: "Cheonan, Republic of Korea",
          work: "VJ Operator",
          detail: [
            require("../assets/05.GscVision/GscVision_01.png"),
            require("../assets/05.GscVision/GscVision_02.png"),
            require("../assets/05.GscVision/GscVision_03.png"),
            require("../assets/05.GscVision/GscVision_04.png"),
          ],
        },
        {
          name: "11th TPO GENERAL ASSEMBLY",
          date: "2023.09.12",
          location: "Jeonju, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/04.TPO/TPO_01.png"),
            require("../assets/04.TPO/TPO_02.png"),
            require("../assets/04.TPO/TPO_03.png"),
            require("../assets/04.TPO/TPO_04.png"),
            require("../assets/04.TPO/TPO_05.png"),
            require("../assets/04.TPO/TPO_06.png"),
            require("../assets/04.TPO/TPO_07.png"),
          ],
        },
        {
          name: "2023 퀴즈 온 코리아",
          date: "2023.09.10",
          location: "KBS Broadcast",
          work: "VJ Operator",
          detail: [
            require("../assets/03.QuizeOnKorea/QuizeOnKorea_01.png"),
            require("../assets/03.QuizeOnKorea/QuizeOnKorea_02.png"),
            require("../assets/03.QuizeOnKorea/QuizeOnKorea_03.png"),
            require("../assets/03.QuizeOnKorea/QuizeOnKorea_04.png"),
            require("../assets/03.QuizeOnKorea/QuizeOnKorea_05.png"),
          ],
        },
        {
          name: "ZIC BRAND DAY",
          date: "2023.09.04",
          location: "Seoul, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/02.ZIC/ZIC_01.png"),
            require("../assets/02.ZIC/ZIC_02.png"),
            require("../assets/02.ZIC/ZIC_03.png"),
            require("../assets/02.ZIC/ZIC_04.png"),
          ],
        },
        {
          name: "Indo-Pacific Economic Framework for Prosperity (IPEF)",
          date: "2023.07.14",
          location: "Busan, Republic of Korea",
          work: "Design, VJ Operator",
          detail: [
            require("../assets/01.IPEF/IPEF_01.png"),
            require("../assets/01.IPEF/IPEF_02.png"),
            require("../assets/01.IPEF/IPEF_03.png"),
            require("../assets/01.IPEF/IPEF_04.png"),
            require("../assets/01.IPEF/IPEF_05.png"),
            require("../assets/01.IPEF/IPEF_06.png"),
            require("../assets/01.IPEF/IPEF_07.png"),
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  width: 90vw;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.mainData {
  display: flex;
}

/* @media (max-width: 600px) {
  .container {
    display: none;
  }
} */
</style>
